/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { EventsContext } from '@/data/context/events';
import { addToCartDelegator } from '@/data/events/delegators/AddToCart';
import { cartPageViewDelegator } from '@/data/events/delegators/CartPageView';
import { cartViewDelegator } from '@/data/events/delegators/CartView';
import { checkoutDelegator } from '@/data/events/delegators/Checkout';
import { checkoutPageViewDelegator } from '@/data/events/delegators/CheckoutPageView';
import { checkoutPaymentDelegator } from '@/data/events/delegators/CheckoutPayment';
import { checkoutShippingDelegator } from '@/data/events/delegators/CheckoutShipping';
import { errorMessageSeenDelegator } from '@/data/events/delegators/ErrorMessageSeen';
import { itemListViewDelegator } from '@/data/events/delegators/ItemListView';
import { paymentFormFailedDelegator } from '@/data/events/delegators/PaymentFormFailed';
import { paymentFormSuccessDelegator } from '@/data/events/delegators/PaymentFormSuccess';
import { productClickDelegator } from '@/data/events/delegators/ProductClick';
import { productViewDelegator } from '@/data/events/delegators/ProductView';
import { promotionClickDelegator } from '@/data/events/delegators/PromotionClick';
import { promotionViewDelegator } from '@/data/events/delegators/PromotionView';
import { purchaseDelegator } from '@/data/events/delegators/Purchase';
import { removeFromCartDelegator } from '@/data/events/delegators/RemoveFromCart';
import { searchResultsViewDelegator } from '@/data/events/delegators/SearchResultsView';
import { useContext, useEffect } from 'react';

export const useEventTracker = () => {
	const { registerEvent } = useContext(EventsContext);

	useEffect(() => {
		registerEvent('onPageView', () => {
			// do something
		});
		registerEvent('onAddToCart', addToCartDelegator);
		registerEvent('onProductView', productViewDelegator);
		registerEvent('onProductClick', productClickDelegator);
		registerEvent('onCheckout', checkoutDelegator);
		registerEvent('onCheckoutShipping', checkoutShippingDelegator);
		registerEvent('onCheckoutPayment', checkoutPaymentDelegator);
		registerEvent('onPromotionClick', promotionClickDelegator);
		registerEvent('onPromotionView', promotionViewDelegator);
		registerEvent('onSearchResultsView', searchResultsViewDelegator);
		registerEvent('onItemListView', itemListViewDelegator);
		registerEvent('onCartView', cartViewDelegator);
		registerEvent('onCartPageView', cartPageViewDelegator);
		registerEvent('onRemoveFromCart', removeFromCartDelegator);
		registerEvent('onCheckoutPageView', checkoutPageViewDelegator);
		registerEvent('onPurchase', purchaseDelegator);

		// custom events
		registerEvent('onErrorMessageSeen', errorMessageSeenDelegator);
		registerEvent('onPaymentFormFailed', paymentFormFailedDelegator);
		registerEvent('onPaymentFormSuccess', paymentFormSuccessDelegator);
	}, [registerEvent]);
	return {};
};
