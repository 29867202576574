import { sendGTMErrorMessageSeenEvent } from '@/data/events/handlers/gtm/ErrorMessageSeen';
import { GTMErrorMessageSeenPayload } from '@/data/types/GTM';

type ErrorMessageSeenDelegatorProps = {
	gtm?: GTMErrorMessageSeenPayload;
};

export const errorMessageSeenDelegator = async (payload: ErrorMessageSeenDelegatorProps) => {
	const { gtm } = payload;
	if (gtm) {
		await sendGTMErrorMessageSeenEvent(gtm);
	}
};
