import { GA4_EVENT_ERROR_MESSAGE_SEEN, PAGE_DATA_LAYER } from '@/data/constants/gtm';
import { getGTMConfig } from '@/data/events/handlers/gtm';
import { GTMErrorMessageSeenPayload } from '@/data/types/GTM';
import { error as logError } from '@/data/utils/loggerUtil';
import TagManager from 'react-gtm-module';

/**
 * Snackbar error message display event
 */
export const measure_GA4 = async (messageSeen: string) => {
	const dataLayerName = PAGE_DATA_LAYER;

	const tagManagerArgsGA4 = {
		dataLayer: {
			event: GA4_EVENT_ERROR_MESSAGE_SEEN,
			message: messageSeen,
		},
		dataLayerName,
	};
	TagManager.dataLayer(tagManagerArgsGA4);
};

export const sendGTMErrorMessageSeenEvent = async (payload: GTMErrorMessageSeenPayload) => {
	const { messageSeen, settings } = payload;
	const { ga4 } = getGTMConfig(settings);
	if (ga4) {
		try {
			await measure_GA4(messageSeen);
		} catch (error) {
			logError(undefined, 'sendGTMErrorMessageSeenEvent: measure_GA4: error: %o', error);
		}
	}
};
