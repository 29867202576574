import { sendGTMPaymentFormSuccessEvent } from '@/data/events/handlers/gtm/PaymentFormSuccess';
import { Settings } from '@/data/Settings';

type PaymentFormSuccessDelegatorProps = {
	gtm?: {
		settings: Settings;
	};
};

export const paymentFormSuccessDelegator = async (payload: PaymentFormSuccessDelegatorProps) => {
	const { gtm } = payload;
	if (gtm) {
		await sendGTMPaymentFormSuccessEvent(gtm);
	}
};
