import { sendGTMPaymentFormFailedEvent } from '@/data/events/handlers/gtm/PaymentFormFailed';
import { Settings } from '@/data/Settings';

type PaymentFormFailedDelegatorProps = {
	gtm?: {
		settings: Settings;
	};
};

export const paymentFormFailedDelegator = async (payload: PaymentFormFailedDelegatorProps) => {
	const { gtm } = payload;
	if (gtm) {
		await sendGTMPaymentFormFailedEvent(gtm);
	}
};
