import { GA4_EVENT_PAYMENT_FORM_SUCCESS, PAGE_DATA_LAYER } from '@/data/constants/gtm';
import { getGTMConfig } from '@/data/events/handlers/gtm';
import { Settings } from '@/data/Settings';
import { error as logError } from '@/data/utils/loggerUtil';
import TagManager from 'react-gtm-module';

/**
 * CenPOS iframe form load success
 */
export const measure_GA4 = async () => {
	const dataLayerName = PAGE_DATA_LAYER;

	const tagManagerArgsGA4 = {
		dataLayer: {
			event: GA4_EVENT_PAYMENT_FORM_SUCCESS,
		},
		dataLayerName,
	};
	TagManager.dataLayer(tagManagerArgsGA4);
};

export const sendGTMPaymentFormSuccessEvent = async (payload: { settings: Settings }) => {
	const { settings } = payload;
	const { ga4 } = getGTMConfig(settings);
	if (ga4) {
		try {
			await measure_GA4();
		} catch (error) {
			logError(
				undefined,
				'Checkout: sendGTMPaymentFormSuccessEvent: measure_GA4: error: %o',
				error
			);
		}
	}
};
